/* eslint-disable */
(function( $ ) {
 
	var fileUploadDefaults = {
		dzForm: 'form[data-dzform]',
		dzFormRedirect: 'dzformredirect',
		dz: '',
		initFileUploadsInterval : true,
		dzFormSubmitting : false,
		isEdit: $('input[name="entryId"]').val() != '' ? true : false,
		isProjects: $.inArray('projects',$(location).attr('href').split("/")) !== -1 ? true : false,
		isIdeas: $.inArray('ideas',$(location).attr('href').split("/")) !== -1 ? true : false,
		isAssets: $.inArray('assets',$(location).attr('href').split("/")) !== -1 ? true : false,
    isPeopleFinder: $.inArray('people-finder',$(location).attr('href').split("/")) !== -1 ? true : false,
		formErrorContainer: $('.form-error-container'),
		formErrorMsg: $('.form-error-container .form-error-msg'),
		volumeId: 0
	};

	
	let initFileUploads = () => {
		
		if(fileUploadDefaults.initFileUploadsInterval){

			if($(fileUploadDefaults.dzForm).length){
				let dzFormId = '#'+$(fileUploadDefaults.dzForm).attr('id');
				fileUploadDefaults.dzForm = $(dzFormId);
				fileUploadDefaults.dzFormRedirect = fileUploadDefaults.dzForm.data(fileUploadDefaults.dzFormRedirect);
				fileUploadDefaults.initFileUploadsInterval = false;

				if(fileUploadDefaults.dzForm.find('.vid').length > 0){
					fileUploadDefaults.volumeId = fileUploadDefaults.dzForm.find('.vid').val();
				}

			} else { return; }

			setupDz();
			setupDzForm();

		}
		
	}

	let setupDz = () => {

		fileUploadDefaults.dz = new DZ( fileUploadDefaults.dzForm, {
			action: 'asset-uploader/upload/dropzone',
			volumeid: fileUploadDefaults.volumeId
		});

	}


	let setupDzForm = () => {

		fileUploadDefaults.dzForm.on("submit", function(e) {

			if($('div[data-dropzone-template]').length == 0){
				return;
			}
			e.preventDefault();
			fileUploadDefaults.dzForm.attr('disabled', 'true');
			if (fileUploadDefaults.dzFormSubmitting) {
				return;
			}
			fileUploadDefaults.dzFormSubmitting = true;
			fileUploadDefaults.dzForm.find('input[type=submit],button[type=submit]').text("Waiting for files to upload...").css('opacity','0.5');
			fileUploadDefaults.dz.check().then( submitDzForm() );

		});

	}


	let submitDzForm = () => {

		var params = fileUploadDefaults.dzForm.find('select, input, textarea').serialize();

		$.ajax({
			type: 'POST',
			url: fileUploadDefaults.dzForm.attr('action'),
			data: params,
			dataType: 'json',
			cache: false,
			success: function(response) {
				if (response.errors) {
					fileUploadDefaults.formErrorContainer.removeClass('hidden');
					fileUploadDefaults.formErrorMsg.html(response.errors.message[0]);
				}
				fileUploadDefaults.dzForm.find('input[type=submit],button[type=submit]').text("Sent!");
				window.location.href = getRedirect();
			},
			error: function(response) {
				if (response.errors) {
					fileUploadDefaults.formErrorContainer.removeClass('hidden');
					fileUploadDefaults.formErrorMsg.html(response.errors.message[0]);
				}
			}
		});


		let getRedirect = () => {
			return fileUploadDefaults.dzFormRedirect + '?notify='+getEntryType()+'-saved';
		}

		let getEntryType = () => {
			if(fileUploadDefaults.isProjects){
				return 'project';
			}
			if(fileUploadDefaults.isIdeas){
				return 'idea';
			}
			if(fileUploadDefaults.isAssets){
				return 'asset';
			}
			if(fileUploadDefaults.isPeopleFinder){
				return 'people-finder';
			}
		}

	}

	const initFileUploadsInterval = setInterval(initFileUploads, 500);
	var initFileUploadsTimer = setInterval(() => {
		if(!fileUploadDefaults.initFileUploadsInterval){
			clearInterval(initFileUploadsInterval);
			clearInterval(initFileUploadsTimer);
		}
	}, 1000)

}( jQuery ));


$(function() {
	'use strict';

	$('.idea-attach-wrp').each(function(index) {
	    var $self = $(this),
	        $list = $self.find('.file-picker-list'),
	        $uploadInput = $self.find('#attachment'),
	        $libraryInput = $self.find('#attachment-library');

	    if($list.length < 1) return;

	    var selectImage = function(id) {
	        $libraryInput.prop('disabled', false);
	        $libraryInput.val(id);
	        $uploadInput.val('');
	    };

	    var deselectImage = function() {
	        $libraryInput.val('');
	        $libraryInput.prop('disabled', true);
	        $list.find('li.active').removeClass('active');
	    };

	    $uploadInput.change(function(){
	        if($uploadInput.val())
	        {
	            deselectImage();
	        }
	     });

	    $list.on('click', 'li', function() {

	        var $self = $(this),
	            id = $self.data('id');

	        if($self.hasClass('active'))
	        {
	            $self.removeClass('active');

	            deselectImage();
	        }
	        else
	        {
	            $self.addClass('active').siblings().removeClass('active');

	            selectImage(id);
	        }
	    });
  	});
  	
});

// // PROJECT FORM HIJACK //
// $(function() {
// 	'use strict';

// 	var $form = $('#projectuploadform');
// 	var $error = $('.error-msg');
// 	var $submitButton = $(".submit-button", $form);
// 	var submitOgText = $submitButton.text();
// 	var submitting = false;

// 	// script for tab steps
// 	$('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {

// 		var href = $(e.target).attr('href');
// 		var $curr = $(".process-model  a[href='" + href + "']").parent();

// 		$('.process-model li').removeClass();

// 		$curr.addClass("active");
// 		$curr.prevAll().addClass("visited");
// 	});
// 	// end  script for tab steps
// 	var dz = new DZ( $form, {
// 		action: 'asset-uploader/upload/dropzone'
// 	});


// 	var submit = function() {
		
// 		var params = $form.find('select, input, textarea').serialize();

// 		$.ajax({
// 			type: 'POST',
// 			url: $form.attr('action'),
// 			data: params,
// 			dataType: 'json',
// 			cache: false,
// 			success: function(response) {
// 				if (response.errors) {
// 					console.log(response.errors);
// 					$error.show();

// 					if (response.errors.message !== undefined) {
// 						$('.error-msg-copy', $form).html(response.errors.message[0]);
// 					}
// 				} else {

// 					$error.hide();
// 					// Reset form
// 			        $form.trigger('reset');

// 			        // Open modal
// 			        var modal = $('[data-remodal-id="editsuccess"]').remodal({
// 			            hashTracking: false
// 			        });
			       
// 			        if (modal != null) {
// 			            modal.open();
// 			        }

// 			        $submitButton.text("Sent!");

// 			        console.log( response );

// 			        // if ( response.publicEditUrl ) {
// 			        // 	window.location = response.publicEditUrl;
// 			        // } else {

//               let entryId = $('input[name="entryId"]').val();
//               let redirect = '/projects/';
//               if(entryId > 0){
// 								redirect = $('input[name=update-redirect]').val();
//                 // redirect = '/projects/project/'+entryId+'/?project=updated';
//               } else {
//                 redirect = '/projects/?project=created';
//               }
// 			        	// TEMP Fix until publicEditUrl is resolved
// 			        	window.location = redirect;
// 				        // window.location.hash = '';
// 				        // window.location.reload();
// 			        // }

			        
// 				}
// 			},
// 			error: function(response) {
// 				if (response.errors) {
// 					$('.error-msg-copy', $form).html(response.errors.message[0]);
// 				}
// 			}
// 		});
// 	};





// 	$form.on("submit", function(e) {

//     if($('div[data-dropzone-template]').length == 0){
//       return;
//     }
    
// 		e.preventDefault();
// 		$form.attr('disabled', 'true');

// 		if ( submitting ) {
// 			return;
// 		}

// 		if ($('.ask-message').val() == '') {
// 			$error.show();
// 			$('.error-msg-copy', $form).html("Message cannot be blank.");
// 			return;
// 		}

// 		// If dz check promise resolves instantly (i.e. no files waiting) then
// 		// this will barely appear before 'sending' message is shown:
// 		$submitButton.text("Waiting for files to upload...").css('opacity','0.5');

// 		dz.check().then( submit );

// 	});
// });





















// // BRAND ASSETS FORM HIJACK //
// $(function() {
// 	'use strict';

// 	var $form = $('#assetsuploadform');
// 	var $error = $('.error-msg');
// 	var $submitButton = $(".submit-button", $form);
// 	var submitOgText = $submitButton.text();
//   	var submitting = false;
//   	var $volumeId = $('#assetsuploadform .vid').val();

//   	var $brandAssetsUrl = $('#assetsuploadform .brandAssetsUrl').val();

// 	// script for tab steps
// 	$('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {

// 		var href = $(e.target).attr('href');
// 		var $curr = $(".process-model  a[href='" + href + "']").parent();

// 		$('.process-model li').removeClass();

// 		$curr.addClass("active");
// 		$curr.prevAll().addClass("visited");
// 	});
// 	// end  script for tab steps
// 	var dz = new DZ( $form, {
//     action: 'asset-uploader/upload/dropzone',
//     volumeid: $volumeId
// 	});


// 	var submit = function() {
		
// 		var params = $form.find('select, input, textarea').serialize();

// 		$.ajax({
// 			type: 'POST',
// 			url: $form.attr('action'),
// 			data: params,
// 			dataType: 'json',
// 			cache: false,
// 			success: function(response) {
// 				if (response.errors) {
// 					console.log(response.errors);
// 					$error.show();

// 					if (response.errors.message !== undefined) {
// 						$('.error-msg-copy', $form).html(response.errors.message[0]);
// 					}
// 				} else {

// 					$error.hide();
// 					// Reset form
// 			        $form.trigger('reset');

// 			        // Open modal
// 			        var modal = $('[data-remodal-id="editsuccess"]').remodal({
// 			            hashTracking: false
// 			        });
			       
// 			        if (modal != null) {
// 			            modal.open();
// 			        }

// 			        $submitButton.text("Sent!");

// 			        console.log( response );

// 			        // if ( response.publicEditUrl ) {
// 			        // 	window.location = response.publicEditUrl;
// 			        // } else {

//            //    let entryId = $('input[name="entryId"]').val();
//               	 let redirect = window.location.origin+'/'+$brandAssetsUrl;
//            //    if(entryId > 0){
//            //      redirect = '/projects/project/'+entryId+'/?project=updated';
//            //    } else {
//            //      redirect = '/projects/?project=created';
//            //    }
// 			        	// TEMP Fix until publicEditUrl is resolved
// 			        	window.location = redirect;
// 				        // window.location.hash = '';
// 				        // window.location.reload();
// 			        // }

			        
// 				}
// 			},
// 			error: function(response) {
// 				if (response.errors) {
// 					$('.error-msg-copy', $form).html(response.errors.message[0]);
// 				}
// 			}
// 		});
// 	};

// 	$form.on("submit", function(e) {

// 		e.preventDefault();
// 		$form.attr('disabled', 'true');

// 		if ( submitting ) {
// 			return;
// 		}

// 		if ($('.ask-message').val() == '') {
// 			$error.show();
// 			$('.error-msg-copy', $form).html("Message cannot be blank.");
// 			return;
// 		}

// 		// If dz check promise resolves instantly (i.e. no files waiting) then
// 		// this will barely appear before 'sending' message is shown:
// 		$submitButton.text("Waiting for files to upload...").css('opacity','0.5');

// 		dz.check().then( submit );

// 	});
// });
