

(function( $ ) {

  var reportingDefaults = {
    initReportingIntervalCheckComplete : false,
	};

  var initReportingInterval = setInterval(() => {
    if($('#reporting-filter-column').length){
      reportingDefaults.initReportingIntervalCheckComplete = true;
      entryTypeSelector();
      categorySelector();
      volumeSelector();
      openModal();
    }
  }, 500);
	var initReportingTimer = setInterval(() => {
		if(reportingDefaults.initReportingIntervalCheckComplete){
			clearInterval(initReportingInterval);
			clearInterval(initReportingTimer);
		}
	}, 1000);


  let entryTypeSelector = () => {		

    $('#reporting-projectentrytype').on('select2:select',function(e){

      var data = e.params.data;
      var entryType = data.id;
      if(entryType){
        location.href = entryType;
      }

    });

  }


  let categorySelector = () => {

    $('#reporting-categorygroup').on('select2:select',function(e){

      var data = e.params.data;
      var entryType = data.id;
      if(entryType){
        location.href = entryType;
      }

    }); 

  }


  let volumeSelector = () => {

    $('#reporting-volume').on('select2:select',function(e){

      var data = e.params.data;
      var entryType = data.id;
      if(entryType){
        location.href = entryType;
      }

    }); 

  }

  let openModal = () => {

    $('a[data-iframesrc').on('click',function(e){

      let iframeSrc = $(this).data('iframesrc');
      $(document).on('opening', '.remodal', function () {
        $('.reporting-modal-iframe').attr('src',iframeSrc);
      });
      $(document).on('closed', '.remodal', function () {
        $('.reporting-modal-iframe').attr('src','#');
      });

    }); 

  }

}( jQuery ));