(function( $ ) {

  var surveyDefaults = {
    initSurveyIntervalCheckComplete : false,
	};

  var initSurveyInterval = setInterval(() => {
    if($('.opinion_scale').length){
      surveyDefaults.initSurveyIntervalCheckComplete = true;
      // captureOpinionScaleSelect();
      // categorySelector();
    }
  }, 500);
	var initSurveyTimer = setInterval(() => {
		if(surveyDefaults.initSurveyIntervalCheckComplete){
			clearInterval(initSurveyInterval);
			clearInterval(initSurveyTimer);
		}
	}, 1000);


  // let entryTypeSelector = () => {		

  //   $('#reporting-projectentrytype').on('select2:select',function(e){

  //     var data = e.params.data;
  //     var entryType = data.id;
  //     if(entryType){
  //       location.href = entryType;
  //     }

  //   });

  // }


  let captureOpinionScaleSelect = () => {

    $('.opinion_scale_option').on('change',function(e){

      var selectedOpinions = '';
      $('.opinion_scale_option:checked').each(function(){
        selectedOpinions = selectedOpinions + $(this).val() + "§";
      });
      selectedOpinions = selectedOpinions.substring(0, selectedOpinions.length - 1);
      $('.captured_opinions_to_save').val(selectedOpinions);
      // console.log($(this).val());

    }); 

  }

}( jQuery ));