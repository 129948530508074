import SwipeListener from 'swipe-listener';

function getCookie(cname) {
	'use strict';

	var name = cname + "=";
	var ca = document.cookie.split(';');
	for(var i=0; i<ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0)==' ') c = c.substring(1);
		if (c.indexOf(name) != -1) return c.substring(name.length,c.length);
	}
	return "";
}


function replaceAll( string, search, replacement ) {
	'use strict';
    return string.replace(new RegExp(search, 'g'), replacement);
}


function setCookie(name, value)
{
	'use strict';
	// create a new date object
	// var d = new Date();
	// put date 1 year into future
	// d.setTime(d.getTime() + (365*24*60*60*1000));
	// format our expiration string for cookie
	//var expires = "expires="+d.toGMTString();

	// furthest into future that is humanly possible
	// http://stackoverflow.com/a/22479460/1769952
	var expires = 2147483647;

	// set cookie
	document.cookie = name + "=" + value + "; path=/; SameSite=None; Secure; expires=" + expires;
}

$( function() {
	'use strict';

	// CONFIG
	var launchData = 'data-launch-slides';
	var slideIndex = 'data-index'; // used as general slide selector
	var slideState = 'data-state';

	var selectors = {
		// be as specific as you fancy, but this should get all
		// slides:
		slide: '[data-slide]',

		// What wraps selectors.slide
		container: '[data-slides]',

		next: '[data-goto="next"]',
		prev: '[data-goto="prev"]',
		launch: '[' + launchData + ']',
		close: '[data-close]'
	};

	var cookieName = 'active__' + replaceAll( window.location.pathname, '/','-');


	var activeFromCookie = parseInt( getCookie( cookieName ) ) || 0;

	// DOM
	var $container = $( selectors.container );
	var $body = $('body');

	var $slides = $container.find( selectors.slide );




	// CONTROLLING THE SLIDES
	var SLIDES = {
		_active: activeFromCookie,
		_activeSlide: false,
		_max: $( selectors.slide ).length - 1,

		_open: false,

		$container: $container,
		$slides: $slides,


		_getSlide: function( i ) {
			var slide = $container.find(selectors.slide + '['+slideIndex+'="'+ i +'"]');
			if ( slide.length ) {
				return slide;
			} else {
				return false;
			}
		},

		markComplete: function( i ) {

			var slide = this._getSlide( i );
			if (slide) {
				slide.attr('data-locked','false');
			}

			this._update();

		},

		launch: function(e) {
			$body.attr('data-state', 'slides-open');
			window.scrollTo(0,0);
			this._open = true;
			if (keyboardEvents) {
				keyboardEvents.activate();
			}

			$(this._activeSlide).trigger('slide.activate');
		},

		close: function() {
			this._open = false;
			$body.attr('data-state', 'slides-closed');
			if (keyboardEvents) {
				keyboardEvents.deactivate();
			}

			$slides.trigger('slide.deactivate');
		},

		_update: function() {
			var self = this;

			var newSlide = false;
			var oldSlide = this._activeSlide;

			$container.find(selectors.slide).each( function( i, slide ) {

				var index = parseInt( $(slide).attr(slideIndex) );

				var status = 'ready';

				if ( self._active === index ) {

					status = 'active';
					newSlide = slide;
				}

				$(slide).attr( slideState, status );


			});

			self._activeSlide = newSlide;
			setCookie(cookieName, self._active );

			if ( this._open ) {
				$(newSlide).trigger('slide.activate');

				if ( oldSlide ) {
					$(oldSlide).trigger('slide.deactivate');
				}
			}


			if ( this._lock >= this._max && !this._complete ) {
				this._complete = true;
				this._completes.forEach( function( fn ) {
					fn();
				});
			}

		},

		_activate: function( i ) {

			var next = this._getSlide( i );
			var current = this._getSlide( this._active );

			if (!next) {
				console.warn(i + " not found");
				return;
			}

			if ( i > this._active && current.attr('data-locked') === 'true' ) {
				console.warn(" current slide is locked, can't go forward");
				return;
			}

			this._active = parseInt( i );
			this._update();


		},

		next: function() {

			var current = this._getSlide( this._active );

			var nextI = this._active + 1;

			if ( nextI > this._max ) {
				return;
				nextI = 0;
			}

			this._activate( nextI );
		},

		prev: function() {
			var prevI = this._active - 1;

			if ( prevI < 0 ) {
				return;
				prevI = this._max;
			}

			this._activate( prevI );
		},

		_complete: false,
		_completes: [],
		onComplete: function( fn ) {
			this._completes.push(fn);
		},



	};

	// Set valid states.
	SLIDES._update();

	var keyboardEvents = new OnKeyboard({
		"left": 	function() { SLIDES.prev(); },
		"right": 	function() { SLIDES.next(); },
		"up":  		function() { SLIDES.prev(); },
		"down":  	function() { SLIDES.next(); },
		"escape":   function() { SLIDES.close(); },
		// "1": 		function() { SLIDES._activate(1-1); },
		// "2": 		function() { SLIDES._activate(2-1); },
		// "3": 		function() { SLIDES._activate(3-1); },
		// "4": 		function() { SLIDES._activate(4-1); },
		// "5": 		function() { SLIDES._activate(5-1); },
		// "6": 		function() { SLIDES._activate(6-1); },
		// "7": 		function() { SLIDES._activate(7-1); },
		// "8": 		function() { SLIDES._activate(8-1); },
		// "9": 		function() { SLIDES._activate(9-1); },
	});

	// EVENTS
	$container.on("click", selectors.next , SLIDES.next.bind( SLIDES ) );
	$container.on("click", selectors.prev , SLIDES.prev.bind( SLIDES ) );
	$container.on("click", selectors.close , SLIDES.close.bind( SLIDES ) );

	$('[data-mark-complete]').on('click', function(e) {
		var slide = $(this).parents(selectors.slide);
		var i = slide.attr( slideIndex );

		SLIDES.markComplete( i );
	});

	keyboardEvents.deactivate();


	$('[data-input-group]').each(function() {

		var $group = $(this);
		var type = $(this).attr('data-input-group');
		var fired = false;

		var check;

		var fire = function() {
			fired = true;
			var slide = $group.parents(selectors.slide);
			var i = slide.attr( slideIndex );
			SLIDES.markComplete( i );
		};


		var $inputs = $group.find('input');

		if (type === 'all') {
			var checks = {};

			check = function() {

				var toFire = true;
				for ( var i in checks ) {
					if ( !checks[i] ) {
						toFire = false;
					}
				}

				if ( toFire ) {
					fire();
				}
			};

			$inputs.each(function(i,input) {

				checks[i] = false;

				$(input).one('change', function() {
					checks[i] = true;
					check();
				});

			});

		} else if ( type === 'radio' || type === 'checkbox' ) {

			check = function() {
				var correct = true;

				$inputs.each(function() {
					if ( !$(this).is(':checked') && this.hasAttribute('data-correct') ) {
						correct = false;
					}

					if ( $(this).is(':checked') && !this.hasAttribute('data-correct') ) {
						correct = false;
					}
				});


				return correct;
			};

			$inputs.on('change', function() {

				var isValid = check();

				if (!fired && isValid) {
					fire();
				}
			});

		} else {
			$inputs.on('input', function() {
				if (!fired) {
					fire();
				}
			});
		}

	});

	$(selectors.close).on("click", SLIDES.close.bind( SLIDES ) );

	$( selectors.launch ).on("click", function(e) {

		var launchIndex = false;

		// If we've set a data attr, and this attribute
		// was set to a number, go to that index. Otherwise
		// we're going to just launch wherever we last were.
		// So if you want to launch teh start, then do
		// data-launch-slides="0"
		if ( launchData.indexOf('data-') === 0 ) {
			launchIndex = $(this).attr(launchData);
			if ( ('' + launchIndex).match(/[0-9]+/) ) {
				SLIDES._activate( launchIndex );
			}
		}

		SLIDES.launch();
	});

	SLIDES.onComplete( function() {
		$container.attr('data-complete','true');
	});

	// data-autoload
	$( selectors.slide ).on('slide.activate', function() {

		//console.log( $(this).attr('data-type') );

		var video = $(this).find('[data-autoload]');
		console.log( video );

		if ( video.length ) {

			if ( video.attr('data-loaded') === 'true' ) {
				video[0].currentTime = 0;
				video[0].play();
			} else {
				var source = $('<source />');
				source.attr('src', video.attr('data-src'));
				source.attr('type', video.attr('data-type'));

				video.append( source );

				// Wait 1 second for transitions to finish.
				setTimeout( function() {
					video[0].play();
				}, 1000 );

				$(video).on('loadeddata', function() {
					video.attr('data-loaded','true');
				});

			}

			video.on('ended', function(){
				let slide = $(this).parents(selectors.slide);
				let i = slide.attr( slideIndex );
				SLIDES.markComplete( i );
			})


		}



	});

	$( selectors.slide ).on('slide.deactivate', function() {
		var video = $(this).find('[data-autoload]');
		if ( video.length ) {
			video[0].pause();
		}
	});

	// Load latest seen
	if ( SLIDES._active > 0 ) {
		SLIDES._update();
	}

	// On first load mark all slides complete if they were supposedly completed
	// in the coookie;
	for (var i = 0; i < SLIDES._active; i++) {
		var s = SLIDES._getSlide(i);
		if ( s ) {
			console.log( "Set " + i + " unlocked");
			s.attr('data-locked','false');
		}
	}

	// EXPOSE
	window.SLIDES = SLIDES;


	// SET THE ANSWERS WRAPPER
	const trainingWrapper = $('.training-accordion__wrapper')
	// CHECK IF THE ANSWER EXISTS
	if(typeof trainingWrapper !== 'undefined'){
		// ON THE CLICK OF ANY OF THE ANSWERS
		trainingWrapper.on('click', '.training-accordion__options', function(e){
			const option = $(this)
			// HIDE ALL THE ANSWERS
			trainingWrapper.find('.training-accordion__answer--y').removeClass('training-accordion__answer--y')
			// MAKE THE CORRECT ANSWER VISIBLE AND GIVE IT THE ANSWER YES CLASS SO THAT WE GIVE IT THE RIGHT ICON POSITION
			option.siblings('.training-accordion__answer').addClass('training-accordion__answer--y')
		})
	}

	// SET THE SWIPE SLIDE CONTAINER
	const swipeContainer = $('.swipeContainer')

	// CHECK IT'S DEFINED
	if(typeof swipeContainer !== 'undefined' && typeof container !== 'undefined'){
		// IF SOMEONE CLICKS LEFT THEN SHOW THE LEFT SLIDE
		swipeContainer.on('click', '.swipeLeft__trigger', function(e){
			e.preventDefault()
			const parent = $(this).parents('.swipeContainer')
			parent.find('.swipeRight').addClass('hidden')
			parent.find('.swipeLeft').toggleClass('hidden')
		})
		// IF SOMEONE CLICKS RIGHT, THEN SHOW THE RIGHT SIDE
		swipeContainer.on('click', '.swipeRight__trigger', function(e){
			e.preventDefault()

			// MAKE SURE WE'RE DOING THIS ON THIS SPECIFIC SLIDE, NOT ANY OTHER
			const parent = $(this).parents('.swipeContainer')
			parent.find('.swipeLeft').addClass('hidden')
			parent.find('.swipeRight').toggleClass('hidden')
		})

		// NOW LETS LISTEN TO TOUCH EVENTS TOO
		const container = document.querySelector('.swipeContainer');
		let listener = SwipeListener(container);

		container.addEventListener('swipe', function (e) {
			const directions = e.detail.directions;

			if (directions.left) {
				//console.log('Swiped left.')
				container.querySelector('.swipeRight').classList.add('hidden')
				container.querySelector('.swipeLeft').classList.remove('hidden')
			}

			if (directions.right) {
				//console.log('Swiped right.');
				container.querySelector('.swipeLeft').classList.add('hidden')
				container.querySelector('.swipeRight').classList.remove('hidden')
			}
		})

		//listener.off();
	}


});

// ADD AN EVENT FOR WHEN THE VIDEO ENDS
//let video = document.getElementsByTagName('videoQuestion')[0];

//video.onended = function(e) {
	// DO THE THING HERE
//};
