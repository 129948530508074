
// Outside of DOM ready, let's do this ourselves?
Dropzone.autoDiscover = false;


// Wrapper around DZ, use our own config,
// hijack a form, so we send asset IDs up
// with a form _instead_ of the actual file
// uploads.
//
// File uploads themselves are managed with a
// seperate action
//
// This only exists so we can be opinionated
// about Dropzone and how our own helpdesk /
// project controllers in craft.
var DZ = function( $form, _options ) {
	'use strict';

	this.options = $.extend( {
		action: '/helpdesk/files/upload',
		selector: '[data-dropzone]',
		template: '[data-dropzone-template]'
	}, _options );

	var $container = $( this.options.selector, $form );


	if ( $container.length === 0 ) {
		return false;
	}

	this.container = $container[0];


	var params = {};

	params[ APP.csrfTokenName ] = APP.csrfTokenValue;
  params.action = this.options.action;

  if(this.options.volumeid){
    params.volumeid = this.options.volumeid;
  }
  

	// Give people an option to
	// replace the action this way?
	if ( this.options.params ) {
		$.extend( params, option.params );
	}

	var dzSettings = {
		url: window.location.href,
		addRemoveLinks: true,
		paramName: 'dzFile',
		previewsContainer: ".dropzone-previews",
		chunking: false,
		clickable: ".dropzone-button",
		thumbnailWidth: 120,
        thumbnailHeight: 120,
        timeout: 0,
		params: params,
		accept: function(file, done) {
			done();
		}
	};


	var	$tpl = $( this.options.template, $form );
	if ( $tpl.length ) {
		dzSettings.previewTemplate = $tpl.html();
	}

	var dz = new Dropzone( this.container , dzSettings );
	this.dropzone = dz;

	this.ready = true;
	this.promises = [];

	var self = this;

	var dzBtn = $('form[data-dzform]').find('input[type=submit],button[type=submit]');
	var dzBtnText = dzBtn.attr("data-text");

	/* --- Events --- */
	dz.on("sending", function( ){

			// Disable the submit button
	    dzBtn.prop("disabled", true).addClass('disabled:opacity-50 cursor-not-allowed').text("Waiting for files to upload...");

		self.ready = false;
	});

	dz.on('queuecomplete', function() {

			// Re-enable the submit button
	    dzBtn.prop("disabled", false).removeClass('disabled:opacity-50 cursor-not-allowed').text(dzBtnText);

		self.ready = true;

		self.promises.forEach( function( p, i ) {
			if ( typeof p === 'object' && p.hasOwnProperty( 'resolve' ) ) {
				p.resolve();
			}
		});

		// empty them, don't keep resolving.
		this.promises = [];
	});


	dz.on("success", function( file, res ) {

		if ( res.fileId ) {
			if ( $form ) {
        var fieldName = 'fields[projectUploads][]';
        if ( $form[0].id == 'assetsuploadform' ) {
          fieldName = 'assetId';
        }
        if ( $form[0].id == 'ideauploadform' ) {
          fieldName = 'fields[ideaUploads][]';
        }
        // console.log($form[0].id);
				$( file.previewElement ).append('<input data-file="'+ file.filename +'" type="hidden" name="'+fieldName+'" value="' + res.fileId + '" />');
			}
		} else {
			console.log("Uploaded file, but rejected by server:");
			console.log( res );
		}
	});

};

/**
 * Check the state of uploads,
 * return a promise, that resolves
 * when all are complete.
 *
 * Note - need to make sure promises don't collide,
 * or are rejected if active?
 *
 * Eg:

 	var dz = new DZ( $form );
	$form.on('submit', function(e) {

		e.preventDefault();

		dz.check().then( function() {
		 	// Any uploads are finished.
		 	// But this wouldn't stop further uploads,
		 	// eg:
		 	$.ajax( .... );
		});
	});
 */
DZ.prototype.check = function() {
	'use strict';
	var p = $.Deferred();

	if ( this.ready ) {
		p.resolve();
	} else {
		this.promises.push( p );
	}

	return p;
};

// Shorthand for adding events to dropzone.
// Not really needed,
// dz.dropzone.on() is the same as dz.on()
DZ.prototype.on = function( evt, cb ) {
	'use strict';
	this.dropzone.on( evt, cb );
};

window.DZ = DZ;
