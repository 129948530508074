
// import 'bodymovin';
// import 'lottie-web';

(function( $ ) {

  var ladderDefaults = {
    initLadderIntervalCheckComplete : false,
	};

  var initLadderInterval = setInterval(() => {
      ladderDefaults.initLadderIntervalCheckComplete = true;
      ladderInit();
  }, 1000);
	var initLadderTimer = setInterval(() => {
		if(ladderDefaults.initLadderIntervalCheckComplete){
			clearInterval(initLadderInterval);
			clearInterval(initLadderTimer);
		}
	}, 1000);

  function ladderInit(){

    let getQueryParameter = (param) => new URLSearchParams(document.location.search.substring(1)).get(param);

    // $('.ladder-dropdown').on('change', function(e){
    $('#run-ladder-filter').on('click',function(e){
      e.stopImmediatePropagation();
      // $('.ladder-loader').removeClass('hidden').addClass('flex');

      let debugParam = '';
      if(getQueryParameter('debug')){
        debugParam = '?debug=true';
      }

      let entrySlug = $(this).data('entryslug');

      let dataToSend = $('.ladder-dropdown').serialize();
      $.ajax({
        type: "get",
        url: "/transformation-ladder/ajax/"+entrySlug+debugParam,
        data: dataToSend,
        dataType: "html",
        success: function (response) {
          $('#ladder-wrapper').html(response);
          $('.ladder-loader').addClass('hidden').removeClass('flex');
        }
      });
    });

    $('#clear-ladder-filter').on('click',function(e){
      e.stopImmediatePropagation();
      // $('.ladder-loader').removeClass('hidden').addClass('flex');
      let dataToSend = [];
      $('.ladder-dropdown').prop('selectedIndex',0);
      $.ajax({
        type: "get",
        url: "/transformation-ladder/ladder",
        data: dataToSend,
        dataType: "html",
        success: function (response) {
          $('#ladder-wrapper').html(response);
          $('.ladder-loader').addClass('hidden').removeClass('flex');
        }
      });
    })

    // Project/Assets List/Grid View Toggle
    // ------------------------------------------------------------
    let percentButton = $('button.percent-view');
    let ratioButton = $('button.ratio-view');
    // let listView = $('ol.list-view');
    // let gridView = $('ol.grid-view');

    // let ratioUid = '';
    // setTimeout(function(){
    //   $('.ratio-step > div').each(function(){
    //     ratioUid = $(this).data('ratioanimateuid');
    //       $(this).addClass(ratioUid);
    //   })
    // },1500);

    ratioButton.on('click',function(){

      // let ratioUid = '';
      // $('.ratio-step').each(function(){
      //   ratioUid = '.' + $(this).data('ratioanimateuid');
      //   if($(this).hasClass(ratioUid)){
      //     $(this).removeClass(ratioUid);
      //   }
      // })

      percentButton.removeClass('active-view');
      ratioButton.addClass('active-view');
      $('.percent-step').addClass('hidden');
      $('.ratio-step').removeClass('hidden');
      $('.ratio-step').addClass('flex');

      // setTimeout(function(){
      //   $('.ratio-step').addClass(ratioUid);
      // },1000);

    });

    percentButton.on('click',function(){

      // let ratioUid = '';
      // $('.ratio-step').each(function(){
      //   ratioUid = '.' + $(this).data('ratioanimateuid');
      //   if($(this).hasClass(ratioUid)){
      //     $(this).removeClass(ratioUid);
      //   }
      // })

      ratioButton.removeClass('active-view');
      percentButton.addClass('active-view');
      $('.percent-step').removeClass('hidden');
      $('.ratio-step').addClass('hidden');
      $('.percent-step').addClass('flex');

      // setTimeout(function(){
      //   $('.ratio-step').each(function(){
      //     ratioUid = '.' + $(this).data('ratioanimateuid');
      //     if($(this).hasClass(ratioUid)){
      //       $(this).removeClass(ratioUid);
      //     }
      //   })
      // },1000);


    });


    // setTimeout(function(){
    //   var animation = bodymovin.loadAnimation({
    //     container: document.getElementById('lottie'), // Required
    //     path: '/data.json', // Required
    //     renderer: 'svg/canvas/html', // Required
    //     loop: false, // Optional
    //     autoplay: true, // Optional
    //     name: "Hello World", // Name for future reference. Optional.
    //   })

    // },2000);


  }

}( jQuery ));
