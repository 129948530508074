import '../css/index.css';

// Vendor CSS
import Alpine from 'alpinejs'
import 'remodal/dist/remodal.css';
import 'remodal/dist/remodal-default-theme.css';
// import 'select-or-die-mt/_src/selectordie.css';
// import 'select2/dist/css/select2.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'dropzone/dist/dropzone.css';
import 'video.js/dist/video-js.min.css';
import '@selectize/selectize/dist/css/selectize.css';
// Tailwind CSS v1
// import '@css/tailwind.css';

// Import new build scripts
// import 'jquery/dist/jquery.min';
// import 'jquery';
// window.jQuery = window.$ = $;

import 'remodal/dist/remodal.min';
// import 'select-or-die-mt/_src/selectordie.min';
// import 'selectize/dist/js/selectize.min.js';
// import '../../node_modules/select2/dist/js/select2';
// select2();
import '@selectize/selectize/dist/js/selectize'

import autoComplete from '@tarekraafat/autocomplete.js';
import 'owl.carousel/dist/owl.carousel.min';
import 'parsleyjs/dist/parsley.js';
// import 'imagesloaded/imagesloaded.pkgd.min.js';
// import 'isotope-layout';
// import 'video.js/dist/video.min.js';
// import videojs from 'video.js/dist/video.min.js';
import 'video.js/dist/video';

// window.videojs = videojs;
import 'dropzone/dist/dropzone.js';
import './lib/onKeyboard.js';
import './DZ.js';
import './training-slides.js';
import './alert.js';
import './file-attachments.js';
// import './notifications.js';
import './reporting.js';
import './surveys.js';
import './inview.js';
import './transformation-ladder.js';

import './asset-library.js';

// import 'alpine-magic-helpers';



window.Alpine = Alpine
Alpine.start()

$(document).ready(function() {

  // VideoJS Multiple
  // ------------------------------------------------------------
  var videoIds = document.querySelectorAll('video.video-js');
  // console.log(videoIds);
  if (videoIds){
    var myPlayer = [];
    for (let i = 0; i < videoIds.length; i++) {
      // console.log(videoIds[i].id);
      myPlayer[i] = videojs(videoIds[i].id);
      // console.log(myPlayer[i]);
    }
  };

  // TermsAgreed Remodal
  // ------------------------------------------------------------
  var inst1 = $('[data-remodal-id=termsAgreed]').remodal();
  if(inst1) {
    inst1.open();
  }
  var inst2 = $('[data-remodal-id=completedTraining]').remodal();
  if(inst2) {
    inst2.open();
  }

  // Project/Assets List/Grid View Toggle
  // ------------------------------------------------------------
  var listButton = $('button.list-view');
  var gridButton = $('button.grid-view');
  var listView = $('ol.list-view');
  var gridView = $('ol.grid-view');

  listButton.on('click',function(){

    // gridButton.removeClass('active-view');
    // listButton.addClass('active-view');
    // listView.removeClass('hidden').addClass('list-table');
    // gridView.removeClass('list-grid').addClass('hidden');
    if ('URLSearchParams' in window) {
      var searchParams = new URLSearchParams(window.location.search);
      searchParams.set("display", "list");
      window.location.search = searchParams.toString();
    }

  });

  gridButton.on('click',function(){

    // listButton.removeClass('active-view');
    // gridButton.addClass('active-view');
    // gridView.removeClass('hidden').addClass('list-grid');
    // listView.removeClass('list-table').addClass('hidden');

    if ('URLSearchParams' in window) {
      var searchParams = new URLSearchParams(window.location.search);
      searchParams.delete("display");
      window.location.search = searchParams.toString();
    }

  });

  // Anchor Navigation Highlighting
  // ------------------------------------------------------------
  var Sidebar = document.getElementById('Sidebar');

  if ('IntersectionObserver' in window && Sidebar)
  {
    var observerOptions = {
      root: null, // relative to document viewport
      rootMargin: '0px', // margin around root. Values are similar to css property. Unitless values not allowed
      threshold: 1.0 // visible amount of item shown in relation to root
    };

    var SidebarAnchorLinks = Sidebar.querySelectorAll('[data-anchor]');

    function onChange(changes, observer) {

      // only take the first
      var changed = changes[0];

      if( ! changed.isIntersecting)
      {
        return;
      }

      var anchor = changed.target.getAttribute('data-anchor');

      var targetLink = Sidebar.querySelector('[data-anchor=' + anchor + ']');

      if( ! targetLink)
      {
        return;
      }

      SidebarAnchorLinks.forEach(function(link, index)
      {
        // this is only for this page
        if( ! link.getAttribute('href').startsWith(window.location.protocol + '//' + window.location.hostname + window.location.pathname))
        {
          return;
        }

        var activeClass = link.getAttribute('data-active-class');

        if( ! activeClass)
        {
          return;
        }

        if(link.getAttribute('data-anchor') == anchor)
        {
          link.classList.add(activeClass);
        }
        else
        {
          link.classList.remove(activeClass);
        }
      });
    }

    var observer = new IntersectionObserver(onChange, observerOptions);

    var headerElements = document.querySelectorAll('.header-module [data-anchor]');

    headerElements.forEach(function (headerElement, index) {
      observer.observe(headerElement);
    });
  }

  // Swipe Navigation
  // ------------------------------------------------------------
  if (Sidebar)
  {
    Sidebar
      .querySelectorAll('[data-swipe-from][data-swipe-to]')
      .forEach(function(button, index) {
        button.addEventListener('click', function (event) {
          // console.log('click!', button);

          var $to = document.getElementById(button.getAttribute('data-swipe-to'));
          var $from = document.getElementById(button.getAttribute('data-swipe-from'));
          var $container = document.getElementById(button.getAttribute('data-swipe-container'));
          var direction = button.getAttribute('data-swipe-direction');

          // console.log(direction, $to, $from, $container);
          $to.style.width = $container.offsetWidth + 'px';

          $container.style.height = $from.offsetHeight + 'px';
          $container.addEventListener('transitionend', function endAnimation (event) {
            $container.style.height = 'auto';
            $container.removeEventListener('transitionend', endAnimation, false);
          }, false);
          $container.style.height = $to.offsetHeight + 'px';

          $from.addEventListener('transitionend',  function endAnimation (event) {
            $from.classList.add('absolute');
            $from.classList.remove('relative');
            $from.removeEventListener('transitionend', endAnimation, false);
          }, false);

          $to.addEventListener('transitionend',  function endAnimation (event) {
            $to.classList.remove('absolute');
            $to.classList.add('relative');
            $to.removeEventListener('transitionend', endAnimation, false);
          }, false);

          if(direction == 'in')
          {
            $from.classList.add('-translate-x-20');
            $to.classList.add('translate-x-none');
          }

          if(direction == 'out')
          {
            $to.classList.add('absolute');
            $to.classList.remove('relative');
            $to.classList.remove('-translate-x-20');
            $from.classList.add('absolute');
            $from.classList.remove('translate-x-none');
          }
        });
    });
  }

  // Toggle Navigation
  // ------------------------------------------------------------
  if (Sidebar)
  {
    Sidebar
      .querySelectorAll('button[data-toggle]')
      .forEach(function(button, index) {

        var arrowSpan = null;
        var arrowSpanClass = null;
        var toggleDiv = null;
        var toggleDivClass = null;

        button.addEventListener('click', function (event) {

          // console.log('click!', button);

          // var $buttonLevel = button.getAttribute('data-level');
          // var $buttonToggle = button.getAttribute('data-toggle');
          // console.log('click level', $buttonLevel);
          // console.log('click toggle', $buttonToggle);


          // if this is the first click, then div will be null, so let's set it
          if(toggleDiv == null)
          {
            toggleDiv = event.currentTarget.parentNode.querySelector('div[data-level]');
          }

          // abort if something goes wrong and we can't find it
          if(toggleDiv == null)
          {
            return;
          }

          toggleDivClass = toggleDiv.getAttribute('data-toggle-class') || null;

          // abort if we don't have a class value to toggle
          if(toggleDivClass == null)
          {
            return;
          }

          if(arrowSpan == null)
          {
            arrowSpan = button.querySelector('[data-arrow]');
          }

          arrowSpanClass = arrowSpan.getAttribute('data-toggle-class') || null;

          // abort if we don't have a class value to toggle
          if(arrowSpanClass == null)
          {
            return;
          }

          // toggle div visibility
          if(toggleDiv.classList.contains(toggleDivClass))
          {
            arrowSpan.classList.add(arrowSpanClass);
            toggleDiv.classList.remove(toggleDivClass);
          }
          else
          {
            arrowSpan.classList.remove(arrowSpanClass);
            toggleDiv.classList.add(toggleDivClass);
          }
        });
    });
  }

  // function isScrolledIntoView(elem) {
  //   var docViewTop = $(window).scrollTop();
  //   var docViewBottom = docViewTop + $(window).height();

  //   var elemTop = $(elem).offset().top;
  //   var elemBottom = elemTop + $(elem).height();

  //   return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
  // }

  // var pageAnchorLinks = document.querySelectorAll('[data-anchor]');
  // var anchorVal = '';
  // if(pageAnchorLinks){
  //   pageAnchorLinks.forEach(function(block, index) {
  //     anchorVal = block.getAttribute('data-anchor');
  //     // $('a[data-anchor-link="#'+anchorVal+'"]').parent().toggleClass('inactive-anchor active-anchor');
  //     if(isScrolledIntoView(block)){
  //       $('a[data-anchor-link]').parent().removeClass("active-anchor");
  //       $('a[data-anchor-link="#'+anchorVal+'"]').parent().addClass("active-anchor");
  //       $('a[data-anchor-link="#'+anchorVal+'"]').parent().removeClass("inactive-anchor");
  //     }
  //     // $(block).inViewport(
  //     //   pageAnchorLinks[index],
  //     //   function(){
  //     //     $('a[data-anchor-link="#'+anchorVal+'"]').parent().addClass("active-anchor");
  //     //     $('a[data-anchor-link="#'+anchorVal+'"]').parent().removeClass("inactive-anchor");
  //     //   },
  //     //   function(){
  //     //     $('a[data-anchor-link="#'+anchorVal+'"]').parent().removeClass("active-anchor");
  //     //     $('a[data-anchor-link="#'+anchorVal+'"]').parent().addClass("inactive-anchor");
  //     //   }
  //     // );

  //     // console.log('a[data-anchor-link="#'+anchorVal+'"]',$('a[data-anchor-link="#'+anchorVal+'"]').parent());
  //   });
  // }



  var inPageAnchors = $('.inpage-anchors');
  if ('IntersectionObserver' in window && inPageAnchors.length)
  {
    var observerOptions = {
      root: null, // relative to document viewport
      rootMargin: '0px', // margin around root. Values are similar to css property. Unitless values not allowed
      threshold: 1.0 // visible amount of item shown in relation to root
    };

    var inPageAnchorLinks = document.querySelectorAll('.inpage-anchor');

    function onChange(changes, observer) {

      // only take the first
      var changed = changes[0];

      if( ! changed.isIntersecting)
      {
        return;
      }

      var anchor = changed.target.getAttribute('data-anchor');

      var targetLink = $('a[data-anchor-link="#'+anchor+'"]').parent();

      // console.log(targetLink);

      if( ! targetLink)
      {
        return;
      }

      inPageAnchorLinks.forEach(function(link, index)
      {
        // this is only for this page
        if( link.getAttribute('href').startsWith(window.location.protocol + '//'))
        {
          return;
        }
        // console.log(link.getAttribute('data-anchor-link'), '#'+anchor);
        if(link.getAttribute('data-anchor-link') == '#'+anchor)
        {
          $(link).parent().addClass('active-anchor');
          $(link).parent().removeClass('inactive-anchor');
        }
        else
        {
          $(link).parent().removeClass('active-anchor');
          $(link).parent().addClass('inactive-anchor');
        }
      });
    }

    var observer = new IntersectionObserver(onChange, observerOptions);

    var inPageSidebarElements = document.querySelectorAll('[data-anchor]');

    inPageSidebarElements.forEach(function (inPageSidebarElement, index) {
      observer.observe(inPageSidebarElement);
    });
  }



  $('.field-lightswitch').on('click touchend',function(){

    let lightSwitchFieldName = $(this).data('lightswitchfield');



    $(this).toggleClass('bg-indigo-600 bg-gray-200');
    $(this).find('span').first().toggleClass('translate-x-5 translate-x-0');

    $(this).find('.field-lightswitch--off').toggleClass('opacity-0 opacity-100');
    $(this).find('.field-lightswitch--off').toggleClass('ease-out ease-in');
    $(this).find('.field-lightswitch--off').toggleClass('duration-100 duration-200');

    $(this).find('.field-lightswitch--on').toggleClass('opacity-100 opacity-0');
    $(this).find('.field-lightswitch--on').toggleClass('ease-in ease-out');
    $(this).find('.field-lightswitch--on').toggleClass('duration-200 duration-100');

    if($(this).hasClass('bg-indigo-600')){
      $('input[name="fields['+lightSwitchFieldName+']"]').val('1');
    } else {
      $('input[name="fields['+lightSwitchFieldName+']"]').val('0');
    }

  });



  // ------------------------------------------------------------
  // Mobile Menu (v2) (show / hide)
  // ------------------------------------------------------------
  $('.menu-reveal-btn').on('click',function() {

    // let sideBarV2 = $('#Sidebar-V2');

    // if(sideBarV2.length){
    //   let sidebarV2Content = $('.sidebar-content');
    //   let screenMediaQuery = document.documentElement.clientWidth;

    //   let menuClosed = sideBarV2.hasClass('menu-closed');
    //   let pageContainer = $('#Page');

    //   if (screenMediaQuery >= 1024) {

    //     if (menuClosed) {
    //       sideBarV2.toggleClass('menu-closed menu-open');
    //       sideBarV2.toggleClass('w-6 w-64');
    //       pageContainer.toggleClass('md:pl-64 lg:pl-0');
    //       sidebarV2Content.toggleClass('md:hidden md:block')
    //     } else {
    //       sideBarV2.toggleClass('menu-open menu-closed');
    //       sideBarV2.toggleClass('w-64 w-6');
    //       pageContainer.toggleClass('md:pl-0 md:pl-64');
    //       sidebarV2Content.toggleClass('md:block md:hidden')
    //     }

    //   } else {

    //     if (menuClosed){
    //       sideBarV2.addClass('md:block');
    //     } else {
    //       sideBarV2.removeClass('md:block');
    //     }
    //   }
    // }

    let sideBarV1 = $('#Sidebar');
    if(sideBarV1.length){
      let sidebarV1Content = $('#Sidebar');
      let screenMediaQuery = document.documentElement.clientWidth;

      let menuClosed = sideBarV1.hasClass('menu-closed');
      let pageContainer = $('#Page');



        if (menuClosed) {
          sideBarV1.removeClass('menu-closed');
          sideBarV1.addClass('menu-open');
          sideBarV1.removeClass('w-6');
          sideBarV1.addClass('w-64');
          // pageContainer.removeClass('lg:pl-64');
          pageContainer.addClass('pl-64');
          pageContainer.removeClass('pl-0');
          sidebarV1Content.removeClass('hidden')
          sidebarV1Content.addClass('block')
        } else {
          sideBarV1.addClass('menu-closed');
          sideBarV1.removeClass('menu-open');
          sideBarV1.addClass('w-64');
          sideBarV1.removeClass('w-6');
          pageContainer.addClass('pl-0');
          pageContainer.removeClass('pl-64');
          sidebarV1Content.addClass('hidden')
          sidebarV1Content.removeClass('block')
        }


    }



  });


  // ------------------------------------------------------------
  // Custom Dropdown
  // ------------------------------------------------------------
  $('body').on('click', function(event) {
    $('.drp-menu').removeClass('st-open');
  });
  $('.drp-menu').on('click', function(event) {
    event.stopPropagation();
    $(this).toggleClass('st-open');
  });

  // ------------------------------------------------------------
  // Alternative Dropdown
  // ------------------------------------------------------------
  $('.js-dropdown').on('click', '.js-dropdown-trigger', function(event) {
    event.preventDefault();
    $(this).parent().find('.js-dropdown-menu').toggleClass('js-dropdown-open');
  });

  // ------------------------------------------------------------
  // Edit Comment Form
  // ------------------------------------------------------------
  $('.js-comment-container').on('click', '.js-edit-comment-form-trigger', function(e){
    e.preventDefault();
    var $commentContainer = $(this).parents('.js-comment-container'),
      $commentControlMenu = $(this).parents('.js-dropdown');

    $commentContainer.toggleClass('js-edit-open')
    $commentControlMenu.find('.js-dropdown-menu').toggleClass('js-dropdown-open');
  })

  // ------------------------------------------------------------
  // Site Header
  // ------------------------------------------------------------
  $('.page-content').on('scroll', function(event) {
    checkScrollPos($(this));
  });

  //Check on JS initialisation
  checkScrollPos($('.page-content'));

  function checkScrollPos($elem) {
    if ($elem.scrollTop() < 10) {
      $('body').addClass('st-scrolled-top');
    } else {
      $('body').removeClass('st-scrolled-top');
    }
  }

  // ------------------------------------------------------------
  // Search drawer
  // ------------------------------------------------------------
  $('.site-search-trigger').on('click', function(event) {

    // Close open dropdown menus
    $('.js-dropdown-menu.js-dropdown-open').toggleClass('js-dropdown-open');

    if ($('body').hasClass('st-search-open')) {

      // Remove class .st-nav-open and .st-drawer-open
      $('body').removeClass('st-drawer-open st-search-open ');

    } else if ($('body').hasClass('st-nav-open')) {

      // Switch .st-nav-open to st-search-open
      $('body').toggleClass('st-nav-open st-search-open');

    } else {

      // Set st-searcg-open and st-drawer-open
      $('body').addClass('st-drawer-open st-search-open');

    }
  });

  // Anchor Smooth Scrolling - JS polyfill
  // Native "smooth" scrolling is available now via `scroll-behavior: smooth`
  // So the below only kicks in if the browser does not support it.
  // ------------------------------------------------------------

  var ScrollContainer = document.querySelector('[data-scroll]');

  if(ScrollContainer && ! ('scrollBehavior' in document.documentElement.style) )
  {

    var pageScrollSpeed = 500;

    $('a[href*="#"]:not([href="#"])').on('click',function(e) {

      // don't hijack empty or non-local links
      if (location.pathname.replace(/^\//, '') != this.pathname.replace(/^\//, '') || location.hostname != this.hostname) {
        return true;
      }

      var target = document.getElementById(this.hash.substring(1));
      if ( ! target)
      {
        return true;
      }

      // we've gotten this far, we're hijacking native scroll
      e.preventDefault();

      // update location to include hash, but DO NOT add to the history,
      // because we've effectively broken the ability to back/forward to
      // hashes via this whole smooth scrolling gimmick.
      window.history.replaceState('', '', this.hash);

      // calculate our scroll distance
      var scrollTop = Math.floor($(ScrollContainer)[0].scrollTop + target.getBoundingClientRect().top);

      $('html,body').animate({
        scrollTop: scrollTop
      }, pageScrollSpeed);
    });
  }

  $('li.inactive-anchor a,li.active-anchor a').on('click',function(e) {
    $('li.active-anchor').toggleClass('active-anchor inactive-anchor');
    $(this).parent().toggleClass('inactive-anchor active-anchor');
    console.log($(this).parent().parent().width());
  });

  // Remove hidden field to allow asset deletion in project edit form
  $('.cb-asset').on('click',function(){
    var assetId = $(this).val();
    var hiddenField = '.hidden-asset-'+assetId;
    var hiddenFieldName = $(hiddenField).attr('name');
    console.log(hiddenFieldName);
    if($(this).is(':checked')){
      var newHiddenFieldName = hiddenFieldName.replace('projectUploads','projectUploads__');
    } else {
      var newHiddenFieldName = hiddenFieldName.replace('projectUploads__','projectUploads');
    }
    console.log(newHiddenFieldName);
    $(hiddenField).attr('name',newHiddenFieldName);
  });



  // Asset filter dropdowns
  // ------------------------------------------------------------

  $('.asset-filter-heading, .people-filter-heading, .accordion-heading').on('click', function() {
    $(this).parent('.asset-filter-group, .people-filter-group, .accordion-group').toggleClass('st-open');
  });

  $('.asset-filter-itm-more, .people-filter-itm-more').on('click', function() {
    $(this).parents('.asset-filter-itm:first, .people-filter-itm:first').toggleClass('st-open');
  });

  // Open groups on page load if there are any selected children
  if ($('.asset-filter-lnk.selected-filter-item, .people-filter-lnk.selected-filter-item').length) {
    $('.asset-filter-lnk.selected-filter-item, .people-filter-lnk.selected-filter-item').parents('.asset-filter-itm, .asset-filter-group, .people-filter-itm, .people-filter-group').addClass('st-open');
  }

  //If the option 'other' is selected, show an input field related to the select
  $('select[data-other-field]').on('change', function() {
    var $selectElem = $(this);
    var otherFieldData = $selectElem.data('other-field');

    if (otherFieldData) {
      otherFieldData = otherFieldData.replace( /(:|\.|\[|\]|,)/g, "\\$1" );// Square brackets in class names cause problems
      $otherFieldGroup = $('.' + otherFieldData)
      if ($selectElem.val() == 'other') {
        if ($otherFieldGroup.length > 0) {
          $otherFieldGroup.removeClass('st-hidden');
          if($otherFieldGroup.hasClass('to-be-required')) {
            $('input', $otherFieldGroup)[0].setAttribute("required", "true");
          }
        }
      }
      else {
        if ($otherFieldGroup.length > 0) {
          $otherFieldGroup.addClass('st-hidden');
          $('input', $otherFieldGroup).removeAttr("required");
        }
      }
    }
  });


  // ------------------------------------------------------------
  // Slideshows
  // ------------------------------------------------------------
  $('.slideshow').each(function() {

    var owl = this,
      settings = $.extend({}, {
      dots: false,
      items: 1,
      nav: true,
      navText: ['<span class="sr-only">Next</span>', '<span class="sr-only">Previous</span>']
    }, $(owl).data('owl') || {});

    $(owl).on('change.owl.carousel', function(event) {

      var videoItems = $($(owl).find('.owl-item')[event.item.index]).find('video');
      if(videoItems.length)
      {
        try {
          videoItems.each(function(i) {
            this.pause();
          });
        } catch(err) {
          // silence is golden
        }
      }
    });

    $(owl).on('changed.owl.carousel', function(event) {

      var videoItems = $($(owl).find('.owl-item')[event.item.index]).find('video');
      if(videoItems.length)
      {
        try {
          videoItems.each(function(i) {
            this.play();
          });
        } catch(err) {
          // silence is golden
        }
      }

    });

    $(owl).on('initialized.owl.carousel', function(event) {

      var videoItems = $($(owl).find('.owl-item')[event.item.index]).find('video');
      if(videoItems.length)
      {
        try {
          videoItems.each(function(i) {
            this.play();
          });
        } catch(err) {
          // silence is golden
        }
      }

    });

    $(owl).owlCarousel(settings);

    if(settings.nav)
    {
      $(owl).on('changed.owl.carousel', function(event) {
        var currentItem = event.item.index + 1;
        window.location.hash = currentItem;
        $('.slideshow-item-count-position').text(currentItem);
      });

      // Set up pagination and current initial slide (spoilers, its 1)
      $(owl).find('.owl-prev').after('<div class="slideshow-pagination"><span class="slideshow-item-count-position">1</span>/<span class="slideshow-item-count-total"></span></div>');
      // Set total slides display
      $(owl).find('.slideshow-item-count-total').text($(owl).find('.owl-item').length);
    }
  });




  // $('.download-collection-btn').on('click',function(){
  //   let assetDownloadForm = $('.download-assets-form');
  //   let assetId = $(this).data('assetid');
  //   $('<input>').attr({
  //     type: 'hidden',
  //     name: 'assetId',
  //     value: assetId
  //   }).appendTo(assetDownloadForm);
  //   assetDownloadForm.trigger('submit');
  // });





























  // ------------------------------------------------------------
  // Select Replacement - Select or Die
  // ------------------------------------------------------------
  // $("form").not("[data-freeform]").find('select:not(.nosod)').selectOrDie({

  // });

  //If the option 'other' is selected, show an input field related to the select
  $('select[data-other-field]').on('change', function() {
    var $selectElem = $(this);
    var otherFieldData = $selectElem.data('other-field');

    if (otherFieldData) {
      otherFieldData = otherFieldData.replace( /(:|\.|\[|\]|,)/g, "\\$1" );// Square brackets in class names cause problems
      $otherFieldGroup = $('.' + otherFieldData)
      if ($selectElem.val() == 'other') {
        if ($otherFieldGroup.length > 0) {
          $otherFieldGroup.removeClass('st-hidden');
          if($otherFieldGroup.hasClass('to-be-required')) {
            $('input', $otherFieldGroup)[0].setAttribute("required", "true");
          }
        }
      }
      else {
        if ($otherFieldGroup.length > 0) {
          $otherFieldGroup.addClass('st-hidden');
          $('input', $otherFieldGroup).removeAttr("required");
        }
      }
    }
  });


  // Tokenised Sharing
	// ------------------------------------------------------------
	/*------------------------------------------------------------------
    [ Tokenised Sharing ]
    @desc : binds to sharing links, fetches response via AJAX, and prompts user with URL
    -------------------------------------------------------------------*/
    $('a.js-share').on('click', function(e) {
      e.preventDefault();

      var shareUrl = $(this).attr('href'),
        shareMsg = $(this).data('share-msg') || '';

      $.ajax(shareUrl).done(function(data) {
        window.prompt(shareMsg, data.url);

        // var publicURL = data.url;
        // console.log(publicURL);
        // window.alert(publicURL);
        // document.getElementById('copy-to-clipboard').innerHTML = publicURL;

      });
    });

    // Copy link to clipboard button
    $('a.js-modal-share-trigger,.js-modal-share').on('click', function(e) {
      e.preventDefault();

      $('.js-copy-btn').addClass('hidden');
      // console.log($(this));
      // console.log($(this).parent());
      // console.log($(this).parent().find('.js-copy-button'));

      if($(this).data('shareurl')!=''){
        var shareUrl = $(this).data('shareurl');
      } else {
        var shareUrl = $(this).attr('href');
      }

        // $shareCopyButton = $(this).parent().find('.js-copy-button');

      $.ajax(shareUrl).done(function(data) {
        var publicURL = data.url;
        //console.log(publicURL);
        //console.log($shareCopyButton);
        // $shareCopyButton.attr('data-public-url', publicURL);
        document.getElementById('copy-to-clipboard').innerHTML = publicURL;
        $('#form-input-tokenUrl').val(publicURL);
        $('.js-copy-btn').removeClass('hidden');
      });
    });

    $('.js-copy-btn').on('click',function(){
      var $temp = $("<input>");
      $("body").append($temp);
      $temp.val($('#copy-to-clipboard').text()).select();
      document.execCommand("copy");
      $temp.remove();
    })


  // ------------------------------------------------------------
  // Isotope Masonry - assets library
  // ------------------------------------------------------------

    // check if element exists on page
    // if(document.getElementById("isotope-masonry")){
    // // alert("Element exists - Do something");

    //     // webpack npm
    //     var Isotope = import 'isotope-layout');

    //     var isoMasonryList = document.querySelectorAll('.masonry-grid'); // returns NodeList
    //     var isoMasonryArray = [...isoMasonryList]; // converts NodeList to Array
    //     isoMasonryArray.forEach(isoMasonry => {

    //       // isotope element
    //       var assetIsoMasonry = isoMasonry;

    //       var iso = new Isotope( assetIsoMasonry, {
    //         // options
    //         percentPosition: true,
    //         itemSelector: '.masonry-grid-item',
    //         masonry: {
    //             // columnWidth: 100,
    //             columnWidth: '.masonry-grid-item',
    //             horizontalOrder: false,
    //             fitWidth: false,
    //         }
    //       });

    //     });

    // } else {
    //   // alert("Element does not exist. Do nothing");
    // }


  // ------------------------------------------------------------
  // Select2
  // ------------------------------------------------------------
  setTimeout(() => {
    $(".js-select2").selectize(
      // {
      //   closeOnSelect : false,
      //   allowHtml: true,
      //   allowClear: false,
      //   tags: false,
      //   tokenSeparators: [',', ' '],
      //   templateResult: function (data) {
      //     // We only really care if there is an element to pull classes from
      //     if (!data.element) {
      //       return data.text;
      //     }
      //     var $element = $(data.element);
      //     var $wrapper = $('<span></span>');
      //     $wrapper.addClass($element[0].className);
      //     $wrapper.text(data.text);
      //     return $wrapper;
      //   }
      // }
    );

    $('.selectize.external-links').selectize({
      onChange: function(value) {
        if (value) {
          window.location = value;
        }
      }
    });

  }, 1000);

    $('.project-list-filter').on('select2:select', function (e) {
      let listFilterOption = $(this).find(':selected').val().split('|');

      if ('URLSearchParams' in window) {
        var searchParams = new URLSearchParams(window.location.search);
        searchParams.set(listFilterOption[0]+'[]', listFilterOption[1]);
        window.location.search = searchParams.toString();
      }
    });

    $('.project-list-filter').on('select2:unselect', function (e) {

      let queryString = 'display=list&';
      let listFilterOption = '';
      $('.project-list-filter').find(':selected').each(function(){
        listFilterOption = $(this).val().split('|');
        console.log($(this).val());
        queryString = queryString + listFilterOption[0] + '[]=' + listFilterOption[1] + '&';
      });
      window.location.search = queryString;
      // let listFilterOption = $(this).find(':selected').val().split('|');

      // if ('URLSearchParams' in window) {
      //   var searchParams = new URLSearchParams(window.location.search);
      //   searchParams.set(listFilterOption[0]+'[]', listFilterOption[1]);
      //   window.location.search = searchParams.toString();
      // }
    });

  // ------------------------------------------------------------
  // Projects Tab Nav Accordion
  // ------------------------------------------------------------
  function onTabClick(event) {

    // console.log(event.target.parentElement);
    if (event.target.parentElement.matches('li')) {

      let activeTabs = document.querySelectorAll('.active');
      // console.log(activeTabs);

      // deactivate existing active tab and panel
      activeTabs.forEach(function(tab) {
        tab.className = tab.className.replace(' active', '');
      });

      // activate new tab and panel
      event.target.parentElement.className += ' active';
      document.getElementById(event.target.href.split('#')[1]).className += ' active';

    }

  }

  let element = document.getElementById('nav-tab');

  if(element){
    element.addEventListener('click', onTabClick, false);
  }


  // if($('#autoComplete').length > 0){
    // ------------------------------------------------------------
    // Projects Add User autoCompleteNew
    // ------------------------------------------------------------

    // Get host and default user image (@TODO get user photo from Twig)
    // var userPhoto = document.location.origin + "/cpresources/65eb7822/images/user.svg?v=1608206640";

    // pass project id variable from twig to js
    // var projectId = document.querySelector('#autoComplete').dataset.projectId;
      // test
      // console.log('Project ID =', projectId);

    // pass Csrf token
    // var csrf = document.querySelector('#csrf').dataset.csrf;
      // test
      // console.log('CSRF Token =', csrf);

      // test project id shows
      // console.log(projectId);


  //   let autoCompletejs = new autoComplete({
  //     data: {                                                 // Data src [Array, Function, Async] | (REQUIRED)
  //       src: async () => {
  //         // Fetch External Data Source
  //         // let source = await fetch("/api/people-finder.json?projectId=9809");
  //         let source = await fetch(`/api/people-finder.json?projectId=${projectId}&limit=0`);
  //         // Format data into JSON
  //         let data = await source.json();
  //         // Return Fetched data
  //         return data.data;
  //       },
  //       // API data
  //       // key: ["id", "name", "email"]
  //       key: ["name"]

  //     },
  //     placeHolder: "Search for a member by name or email",      // Place Holder text                 | (Optional)
  //     selector: "#autoComplete",                                // Input field selector              | (Optional)
  //     searchEngine: "strict",                                   // Search Engine type/mode           | (Optional)
  //     resultsList: {
  //       // Rendered results list object      | (Optional)
  //       render: true,
  //       container: source => {
  //         source.setAttribute("id", "selection");
  //       },
  //       destination: document.querySelector("#autoComplete"),
  //       position: "afterend",
  //       element: "div"
  //     },
  //     maxResults: 4,                                            // Max. number of rendered results | (Optional)
  //     resultItem: {
  //       // Rendered result item            | (Optional)
  //       content: (data, source) => {

  //         var enableAdd = 'disabled';
  //         var enableAddStyles = 'disabled:opacity-50 cursor-not-allowed';
  //         if(data.value.enableAdd == true){
  //           enableAdd = '';
  //           enableAddStyles = '';
  //         }

  //         let result = (`


  //         <div class="flex py-2 border-t border-white">
  //             <div class="w-1/6 my-auto">
  //                 <div class="pl-3" itemprop="image">


  //                     <img class="rounded-full w-12 h-12 border border-gray-400 !important;" src="${data.value.photoUrl || '/cpresources/65eb7822/images/user.svg?v=1608206640'}" />
  //                     <!-- <img class="rounded-full w-12 h-12 border border-gray-400 !important;" src="${userPhoto}" /> -->
  //                     <!-- <img class="rounded-full w-12 h-12 border border-white;" src="${data.value.photoUrl}" alt="User Image" /> -->

  //                 </div>
  //             </div>

  //             <div class="w-5/6 my-auto text-left">
  //                 ${data.value.id}, ${data.value.name}, ${data.value.email}
  //             </div>


  //             <form method="POST">
  //             <input type="hidden" name="entryId" value="${projectId}">
  //             <input type="hidden" name="CRAFT_CSRF_TOKEN" value="${csrf}">
  //             <input type="hidden" name="fields[projectUsers][]" value="${data.value.id}">
  //             <input type="hidden" name="notifyUsers" value="${data.value.id}">
  //             <input type="hidden" name="notificationTemplate" value="add_user">
  //             ${data.value.currentUsers}
  //             <input type="hidden" name="action" value="entries/save-entry">
  //             <div class="w-1/6 my-auto text-right pr-2">
  //                 <button class="outline-button-tw ${enableAddStyles}" type="submit" ${enableAdd}>Add</button>
  //             </div>
  //           </form>


  //         </div>

  //                       `);

  //         source.innerHTML = result;
  //       },
  //       element: "li"
  //     },

  //     noResults: () => {
  //       // Action script on noResults      | (Optional)
  //       let result = document.createElement("li");
  //       result.setAttribute("class", "no_result");
  //       result.setAttribute("tabindex", "1");
  //       result.innerHTML = "No Results";
  //       document.querySelector("#selection").appendChild(result);
  //     }
  //   });
  // }

  var addPersonRequestInProcess = false;
  $(document).on('submit','.add-person-people-finder-form, .remove-person-people-finder-form',function(event) {

    if (addPersonRequestInProcess) {
      return false; // Return without initiating a new request
    }
    addPersonRequestInProcess = true;

    event.preventDefault();

    var formData = $(this).serialize();
    var thisForm = $(this);

    // $('.add-person-response .user-message').hide();

    $.ajax({
      type: "POST",
      url: '/',
      data: formData,
      dataType: "json",
      success: function(response) {


        htmx.on('htmx:afterSwap', function(event) {


          $('.add-person-response').css({opacity: 0, display: 'block'}).animate({opacity: 1, marginTop: '20px'}, 150);
          // $('.add-person-response .user-'+msgClasses+'-message').show();

          setTimeout(() => {
            $('.add-person-response').css({opacity: 1, display: 'block'}).animate({opacity: 0, marginTop: '0px'}, 150);
            // $('.add-person-response .user-message').hide();
            addPersonRequestInProcess = false;
          }, 2000);
        });

        htmx.trigger('#people-results', 'refresh');

      },
      error: function(jqXHR, textStatus, errorThrown) {
        console.error("Error submitting form: ", jqXHR);
        addPersonRequestInProcess = false;
      }
    });

    return false;
  });


  var createCollectionRequestInProcess = false;
  $(document).on('click','.create-collection',function(event) {

    if (createCollectionRequestInProcess) {
      return false; // Return without initiating a new request
    }
    createCollectionRequestInProcess = true;

    event.preventDefault();

    var thisObj = $(this);
    let collectionUrlHref = thisObj.attr('href');

    // $('.add-person-response .user-message').hide();

    $.ajax({
      type: "GET",
      url: collectionUrlHref,
      data: [],
      dataType: "json",
      success: function(response) {


        // htmx.on('htmx:afterSwap', function(event) {


        //   $('.add-person-response').css({opacity: 0, display: 'block'}).animate({opacity: 1, marginTop: '20px'}, 150);
        //   // $('.add-person-response .user-'+msgClasses+'-message').show();

        //   setTimeout(() => {
        //     $('.add-person-response').css({opacity: 1, display: 'block'}).animate({opacity: 0, marginTop: '0px'}, 150);
        //     // $('.add-person-response .user-message').hide();
        //     addPersonRequestInProcess = false;
        //   }, 2000);
        // });

        htmx.trigger('#collections-list', 'refresh');

      },
      error: function(jqXHR, textStatus, errorThrown) {
        console.error("Error submitting form: ", jqXHR);
        createCollectionRequestInProcess = false;
      }
    });

    return false;
  });


  $(document).on('click','.add-item-collection',function(event) {

    let itemHref = $(this).attr('href');
    let thisLink = $(this);
    $.ajax({
      type: "GET",
      url: itemHref,
      dataType: "json",
      success: function(response) {
        var circle = thisLink.siblings('div'); // get the sibling div element
        // do something with the sibling div element
        circle.toggleClass('bg-green-500');
      },
      error: function(jqXHR, textStatus, errorThrown) {
        console.error(textStatus, jqXHR,errorThrown);
      }
    });

    return false;

  });



  // $('select[data-links-external]').on('change',function(){
  //   let fileURL = $(this).val();
  //   var fileName = $(this).find(':selected').data('filename');


  //   // for non-IE
  //   if (!window.ActiveXObject) {
  //     var save = document.createElement('a');
  //     save.href = fileURL;
  //     save.target = '_blank';
  //     save.download = fileName;

  //     var evt = new MouseEvent('click', {
  //         'view': window,
  //         'bubbles': true,
  //         'cancelable': false
  //     });
  //     save.dispatchEvent(evt);

  //     (window.URL || window.webkitURL).revokeObjectURL(save.href);
  //   }


  // })

  $('#login-form').on('submit', function(e) {
    if($('#login-form').find('.is-invalid').length > 0) {
      return false;
    }
  });


});


