/* eslint-disable */


(function( $ ) {
 
	var alertDefaults = {
    alertPopup : '',
    initAlertIntervalCheckComplete : false,
    twUIClasses : {
      entering: "transform ease-out duration-300 transition",
      enteringFrom: "translate-y-2 sm:translate-y-0 sm:translate-x-2",
      enteringTo: "translate-y-0 opacity-100 flex sm:translate-x-0",
      leaving: "transition ease-in duration-100",
      leavingTo: "opacity-0 hidden",
    }
	};

  var initAlertInterval = setInterval(() => {
    if($('.show-alert').length){
      alertDefaults.initAlertIntervalCheckComplete = true;
      $('.show-alert').on('click',function(){
        // console.log(alertDefaults.alertPopup);
        alertDefaults.alertPopup = $('.alert-popup');
        var alertMessage = $(this).data('alertmessage');
        if(alertMessage){
          alertDefaults.alertPopup.find('.alert-message').text(alertMessage);
        }
        showAlert();
        return false;
      });  
      $('.hide-alert').on('click',function(){
        hideAlert();
        return false;
      });    
      $('.action-alert').on('click',function(){
        $('.show-alert').closest('form').submit();
      });            
    }
  }, 500);
	var initAlertTimer = setInterval(() => {
		if(alertDefaults.initAlertIntervalCheckComplete){
			clearInterval(initAlertInterval);
			clearInterval(initAlertTimer);
		}
	}, 1000);

  let showAlert = () => {
    alertDefaults.alertPopup.removeClass(alertDefaults.twUIClasses.leavingTo);
    alertDefaults.alertPopup.addClass(alertDefaults.twUIClasses.entering);
    alertDefaults.alertPopup.addClass(alertDefaults.twUIClasses.enteringFrom);
    alertDefaults.alertPopup.addClass(alertDefaults.twUIClasses.enteringTo);
  };

  let hideAlert = () => {
    alertDefaults.alertPopup.removeClass(alertDefaults.twUIClasses.entering);
    alertDefaults.alertPopup.removeClass(alertDefaults.twUIClasses.enteringFrom);
    alertDefaults.alertPopup.removeClass(alertDefaults.twUIClasses.enteringTo);
    alertDefaults.alertPopup.addClass(alertDefaults.twUIClasses.leaving);
    alertDefaults.alertPopup.addClass(alertDefaults.twUIClasses.leavingTo);
  }

}( jQuery ));

