(function(window, factory) {
			'use strict';
			var OnKeyboard = factory(window, window.document);
			window.OnKeyboard = OnKeyboard;
			if(typeof module == 'object' && module.exports){
				module.exports = OnKeyboard;
			}
		})(window, function(window, document) {
			'use strict';
			// var keycodes = {
			// 	left: 37,
			// 	right: 39,
			// 	up: 38,
			// 	down: 40,
			// 	escape: 27
			// };

			var _getProp = function( prop, obj ) {

			    if ( obj.hasOwnProperty( prop ) ) {
			        return obj[prop];
			    } else {
			        return undefined;
			    }

			};


			var keycodes = [
				['left', 37],
				['right', 39],
				['up', 38],
				['down', 40],
				['escape', 2],
				["0", 48],
				["1", 49],
				["2", 50],
				["3", 51],
				["4", 52],
				["5", 53],
				["6", 54],
				["7", 55],
				["8", 56],
				["9", 57],
				["a", 65],
				["b", 66],
				["c", 67],
				["d", 68],
				["e", 69],
				["f", 70],
				["g", 71],
				["h", 72],
				["i", 73],
				["j", 74],
				["k", 75],
				["l", 76],
				["m", 77],
				["n", 78],
				["o", 79],
				["p", 80],
				["q", 81],
				["r", 82],
				["s", 83],
				["t", 84],
				["u", 85],
				["v", 86],
				["w", 87],
				["x", 88],
				["y", 89],
				["z", 90]		 
			];




			var OnKeyboard = function( options ) {
				var self = this;

				self._options = options || {};
				self._active = true;
				self.fire = {};


				keycodes.forEach(function( k ) {

					var n = k[1];
					var c = k[0];

					var cb = _getProp( c, self._options );

					if (typeof cb === 'function') {
						self.fire[ n ] = function( e, isMetaKey ) {
							if ( self._active ) {
								cb( e, isMetaKey );
							}
						};
					} else {
						self.fire[ n ] = false;
					}

				});

				self._event = function(e) {
					if ( self.fire[ e.which ] ) {
						self.fire[ e.which ](e, e.metaKey );
					}

				};

				document.addEventListener( 'keydown', self._event );
			};

			OnKeyboard.prototype.deactivate = function() {
				this._active = false;
			};

			OnKeyboard.prototype.activate = function() {
				this._active = true;
			};

			OnKeyboard.prototype.destroy = function() {
				this._active = false;
				document.removeEventListener( 'keydown', this._event );
			};

			return OnKeyboard;


		});