export default class assetLibrary {

 
  constructor(){
  }

  init(){

    window.assetLibrary.createComponents();

    $(document).on('click','.make-asset-access-request',function(){
      // let formToSubmit = $(this).data('accessrequestform');
      // $(formToSubmit).find('button').trigger('click');
      // $(this).text('Request sent');
      // return false;

      let formToSubmit = $(this).data('accessrequestform');
      $('.access-request-form-slideout').empty();
      $('.access-request-form-slideout').html($(formToSubmit).html());
      $('.access-request-form-slideout .make-asset-access-request').removeClass('make-asset-access-request');
      $('#access-request-form-slideout-container').fadeToggle();
      // $(this).text('Request sent');
      return false;

    });

    $(document).on('click','.close-asset-access-request',function(){
      $('#access-request-form-slideout-container').hide();
      return false;
    })

  }

  // Select the target node.
  attachEventsToAssetPanel(){

    let self = this;

    let colourOptions = $('.asset-colour');
    $(document).on('change',colourOptions,function(){
      let formatOptions = $('.format-option');
      formatOptions.attr('disabled', true);
      $('.asset-colour:checked').each(function(){
        
        var colourOptionsList = $(this).attr('data-formatids').split('|');
        formatOptions.each(function(){
          var formatOption = $(this);
          
          if(colourOptionsList.indexOf(formatOption.val())>-1){
            // console.log('found ' + formatOption.val() + ' in ', colourOptionsList);
            // console.log('enabled');
            formatOption.removeAttr('disabled');
          // } else {
          //   console.log('disabled');
          //   formatOption.prop('checked', false);
          }
        });
      });
    });
    

    $(document).on('change','.format-option',function(){
      let hasSelectedOption = $('.format-option option:selected');
      if(hasSelectedOption){
        window.assetLibrary._enableButtons('.add-to-collection-button, .download-collection-button');
      } else {
        window.assetLibrary._disableButtons('.add-to-collection-button, .download-collection-button');
      }
    })
    
    
  


    // DOWNLOAD COLLECTION
    $(document).on('click','.download-collection-button',function(){

      let formData = [{
        'name' : 'assetId',
        'value' : $(this).data('assetid')
      }];

      let selectedColourOptions = $('.asset-colour:checked');
      formData = formData.concat(
        selectedColourOptions.map(
          function() {
            return {"name": "asset-colour[]", "value": $(this).val()}
          }).get()
      );
      
      let selectedFormatOptions = $('.format-option:checked');
      formData = formData.concat(
        selectedFormatOptions.map(
          function() {
            return {"name": "asset-format[]", "value":  $(this).val()}
          }).get()
      );
      
      $.ajax({
        type: "get",
        url: "/api/get-asset-ids",
        data: formData,
        success: function (response) {

          
          let assetIdsToDownload = response.data[0].ids;
          let downloadForm = $('form.download-assets-form');
          $('.hidden-assets-to-download').remove();
          for (let i = 0; i < assetIdsToDownload.length; i++) {          
            $('<input>').attr({
              type: 'hidden',
              value: assetIdsToDownload[i],
              name: 'files[]',
              class: 'hidden-assets-to-download',
            }).appendTo(downloadForm);
          }
          downloadForm.submit();

        }
      });

    });





    // CREATE COLLECTION
    $(document).on('click','[data-createcollection]',function(){
      
      let newCollectionID = 0;
      // let parentAssetID = $(this).data('parentassetid');
      let getNewListId = function() {
        $.ajax({
          type: "get",
          url: '/api/get-created-collection-id',
          dataType: 'json',
          success: function (response) {
            newCollectionID = response.data[0].collection_id;
            self.saveMultipleAssetsIdsToCollection(newCollectionID);
          }  
        })
      }

      let createUrl = $(this).attr('href');
      $.ajax({
        type: "get",
        url: createUrl,
        dataType: 'json',
        success: function (response) {
          getNewListId();
          window.assetLibrary._loadCollectionsList();
        }
      });  

      return false;

    });


    // Add Item to Collection
    $(document).on('click','[data-addtocollection]',function(){
      let collectionID = $(this).data('addtocollection');
      self.saveMultipleAssetsIdsToCollection(collectionID);
      // let assetID = $(this).data('assetid');
      // console.log(collectionID,parentAssetID);
      // self.saveMultipleAssetsIdsToCollection(collectionID,parentAssetID);
      return false;
    });


  }


  // Add Multiple Items to Collection
  // @collectionId
  saveMultipleAssetsIdsToCollection(collectionID){

    // console.log(collectionID,parentAssetID);

    let self = this;
    
    let formData = [{
      'name' : 'assetId',
      'value' : $('a[data-assetId]').data('assetid')
    }];

    let selectedColourOptions = $('.asset-colour:checked');
    formData = formData.concat(
      selectedColourOptions.map(
        function() {
          return {"name": "asset-colour[]", "value": $(this).val()}
        }).get()
    );
    
    // let dataToSend = formData;
    let selectedFormatOptions = $('.format-option:checked');
    formData = formData.concat(
      selectedFormatOptions.map(
        function() {
          return {"name": "asset-format[]", "value":  $(this).val()}
        }).get()
    );

    var dataToSend = new FormData();
    $.ajax({
      type: "get",
      url: "/api/get-asset-ids",
      dataType: 'json',
      data: formData,
      success: function (response) {
        let assetIdsToDownload = response.data[0].ids;
        let assetIdName = '';
        let itemCount = 0;
        if(assetIdsToDownload.length>0){
          for (let i = 0; i < assetIdsToDownload.length; i++) {
            itemCount = i + 1;
            assetIdName = 'items['+itemCount+'][elementId]';
            dataToSend.append(assetIdName, assetIdsToDownload[i]);
            // dataToSend.append('items['+itemCount+'][fields][relatedAssets]['+i+']' , parentAssetID);
          }
        } else {
          assetIdName = 'items[0][elementId]';
          dataToSend.append(assetIdName, $('a[data-assetId]').data('assetid'));
          // dataToSend.append('items[0][fields][relatedAssets][0]' , $('a[data-assetId]').data('assetid'));
        }
        

        let tokenName = window.Craft.csrfTokenName; 
        let tokenVal = window.Craft.csrfTokenValue;

        dataToSend.append(tokenName , tokenVal);
        dataToSend.append('action' , 'wishlist/items/add');
        dataToSend.append('listId' , collectionID);
        

        // console.log(collectionID,parentAssetID);

        // console.log(dataToSend, dataToSend.values);
        $.ajax({
          type: "POST",
          url: '/',
          data: dataToSend,
          enctype: 'multipart/form-data',
          processData: false,
          contentType: false,
          cache: false,
          timeout: 800000,
          dataType: 'json',
          success: function(data)
          {
            
            window.assetLibrary.displayCollectionMessage('Your assets have been saved.');
          }
        });    
      }        
    });
  }


  // Display confirmation message
  displayCollectionMessage(message){
    let ajaxMessage = $('.ajax-message');
    ajaxMessage.html(message);
    ajaxMessage.fadeIn().delay(6000).fadeOut();
  }


  // Attach Collection Methods to Collections Panel
  attachEventsToCollectionsPanel(){

    $(document).on('click','[data-ajax-removeitem]',function(){
      let linkHref = $(this).attr('href');
      let itemId = $(this).data('ajax-removeitem');
      $.ajax({
        type: "get",
        url: linkHref,
        dataType: 'json',
        success: function (response) {
          $('#collection-item-'+itemId).addClass('hidden');
          // $('.collection-message-'+itemId).removeClass('hidden');
          // $('.collection-message-'+itemId).addClass('flex');
          window.assetLibrary.displayCollectionMessage('Item was removed');
        }  
      })
      return false;
    });
  
    $(document).on('click','[data-downloadcollection]',function(){
      let downloadForm = $(this).data('downloadcollection');
      $('.'+downloadForm).submit();
      return false;
    })

    $(document).on('click','[data-ajax-deletecollection]',function(){
      let self = $(this);
      let linkHref = self.attr('href');
      $.ajax({
        type: "get",
        url: linkHref,
        dataType: 'json',
        success: function (response) {
          self.closest('li').fadeOut();
          window.assetLibrary.displayCollectionMessage('Collection was deleted');
        }  
      })
      return false;
    });

    $(document).on('submit','[data-ajaxform]',function(e) {
      var formData = $(this).serialize();
      $.ajax({
          type:'post',
          url:'/',
          data:formData,
          dataType: 'json',
          success:function(result){
            window.assetLibrary.displayCollectionMessage('Your collection has been renamed.');
          }
      });
      e.preventDefault();
    });
    

  }

  createComponents(){

    window.Components = window.Components ?? {};
    window.Components["formatsDropdown"] = (function anonymous() {
        return function (e) {
            return {
                init() {
                    (this.optionCount = this.$refs.listbox.children.length),
                        this.$watch("activeIndex", (e) => {
                            this.open && (null !== this.activeIndex ? (this.activeDescendant = this.$refs.listbox.children[this.activeIndex].id) : (this.activeDescendant = ""));
                        });
                },
                activeDescendant: null,
                optionCount: null,
                open: !1,
                activeIndex: null,
                selectedIndex: [],
                get active() {
                    return this.items[this.activeIndex];
                },
                get [e.modelName || "selected"]() {
                    return this.items[this.selectedIndex];
                },
                choose(e) {
                    /*
                    (this.selectedIndex = e);
    
                    const colourOptions = document.querySelectorAll('.asset-colour');
                    const formatOptions = document.querySelectorAll('.format-option');
                    
                    [...colourOptions].forEach(
                      colourOption => {
                        colourOptionsList = colourOption.getAttribute('data-formatids').split('|');
                        //console.log(colourOptionsList);
                        [...formatOptions].forEach(
                          formatOption => {
                            console.log(colourOptionsList,formatOption.value,colourOptionsList.includes(formatOption.value));
                            if(colourOptionsList.includes(formatOption.value)){
                              colourOption.disabled = false;
                            } else {
                              colourOption.disabled = true;
                            }
                            
                          }
                        )
                      }
                    );
    
                    console.log('choose');
                    */
                },
                onButtonClick() {
                    (this.open = 1), this.$refs.button.focus();
                    
                },
                onOptionSelect() {
                    null !== this.activeIndex && (this.selectedIndex = this.activeIndex), /*(this.open = !1),*/ this.$refs.button.focus();
                    
                },
                onEscape() {
                    (this.open = !1), this.$refs.button.focus();
                },
                ...e,
            };
        };
    })();
    
    
    
    
    

    
    window.Components = window.Components ?? {};
    window.Components["manageCollectionsDropdown"] = (function anonymous() {
        return function (e) {
            return {
                init() {},
                activeDescendant: null,
                optionCount: null,
                open: !1,
                activeIndex: null,
                selectedIndex: [],
                collectionSlideoutContent: false,
                onButtonClick() {
                  fetch(`/asset-library/manage-collections`)
                  .then(response => response.text())
                  .then((html) => {
                      $('.collections-slideout-content').html(html);
                      window.assetLibrary.attachEventsToCollectionsPanel()
                      // window.assetLibrary._expandSlider('wide')
                      $('.asset-slideout-content').hide();
                      $('.collections-slideout-content').show();
                  });
                },
                onEscape() {
                    (this.open = !1), this.$refs.button.focus();
                },
                ...e,
            };
        };
    })();



    
    window.Components = window.Components ?? {};
    window.Components["addToCollectionDropdown"] = (function anonymous() {
      return function (e = { open: !1 }) {
        return {
            init() {
                (this.items = Array.from(this.$el.querySelectorAll('[role="menuitem"]'))),
                    this.$watch("open", () => {
                        this.open && (this.activeIndex = -1);
                    });
            },
            activeDescendant: null,
            activeIndex: null,
            items: null,
            open: e.open,
            focusButton() {
                this.$refs.button.focus();
            },
            onButtonClick(assetId) {
              
              let isAddButtonEnabled = window.assetLibrary._isButtonEnabled('.add-to-collection-button');

              if(!isAddButtonEnabled){
                return;
              }

              (this.open = !this.open),
                  this.open &&
                      this.$nextTick(() => {
                          this.$refs["menu-items"].focus();
                      });
              
              
    
            },
            onButtonEnter() {
                (this.open = !this.open),
                    this.open &&
                        ((this.activeIndex = 0),
                        (this.activeDescendant = this.items[this.activeIndex].id),
                        this.$nextTick(() => {
                            this.$refs["menu-items"].focus();
                        }));
            },
            onClickAway(e) {
                if (this.open) {
                    const t = ["[contentEditable=true]", "[tabindex]", "a[href]", "area[href]", "button:not([disabled])", "iframe", "input:not([disabled])", "select:not([disabled])", "textarea:not([disabled])"]
                        .map((e) => `${e}:not([tabindex='-1'])`)
                        .join(",");
                    (this.open = !1), e.target.closest(t) || this.focusButton();
                }
            },
        };
      };
    })();
    //console.log(Components);
  


  }

    





  categoriesDropdown() {

    return {
        showCategories: false,
        openCatsDropdown() { this.showCategories = true },
        closeCatsDropdown() { this.showCategories = false },
        isCatsDropdownOpen() { return this.showCategories === true },
        toggleCatsDropdown() {

            console.log('showCategories');    
            this.showCategories = !this.showCategories 
             
        }
    }

  }

  sortByDropdown() {

      return {
          showSortBy: false,
          openSortByDropdown() { this.showSortBy = true },
          closeSortByDropdown() { this.showSortBy = false },
          isSortByDropdownOpen() { return this.showSortBy === true },
          toggleSortByDropdown() {

              console.log('showSortBy');    
              this.showSortBy = !this.showSortBy 
              
          }
      }

  } 

  dropdown() {
    
    return {
          showId: -1,
          closeDropdown() { 
              console.log('close dropdown');
              this.showId = -1
          },
          toggleDropdown(id) {
              
              if (this.showId === id) {
                  this.showId = -1
              } else {
                  this.showId = id
              }
              
          }
      }
  }

  updateGrid() {

    return {
        changeGrid: false, 
        toggleGrid() {

            // swap out short and long versions of the asset name text
            const shortAssetTexts = document.querySelectorAll('.assetTextShort');
            const longAssetTexts = document.querySelectorAll('.assetTextLong');

            for (const shortAssetText of shortAssetTexts) {

                if (shortAssetText.style.display != 'none')
                {
                    shortAssetText.style.display = 'none';
                } else {
                    shortAssetText.style.display = 'block';
                }
            }

            for (const longAssetText of longAssetTexts) {

                if (longAssetText.style.display == 'block')
                {
                    longAssetText.style.display = 'none';
                } else{
                    longAssetText.style.display = 'block';
                }
            }

            console.log('toggleGrid');
            this.changeGrid = !this.changeGrid

        }
    }

  }

  slideout() {

    let currentId = 0;
    return {
          showSlideout: false,
          assetSlideoutContent: '',
          expandedSlider: false,
          openSlideout(assetId, setDropdown) {

              console.log('openSlideout');

              this.fetchPage(assetId);
              this.showSlideout = true;

              if(setDropdown){
                this.closeDropdown()
              }

              

          },
          closeSlideout() {

              console.log('closeSlideout');
              if($('.asset-slideout-content').is(':visible')){
                this.showSlideout = false
              } else {
                $('.collections-slideout-content').hide();
                $('.asset-slideout-content').show();
              }


          },
          expandSlideout() {

              // console.log('expandSlideout');

              // this.expandedSlider = !this.expandedSlider;
              // let slideContainer = $('.slideoutContentContainer');
              // if(slideContainer.hasClass('max-w-xl')){
              //   slideContainer.removeClass('max-w-xl');
              //   slideContainer.addClass('max-w-wide-panel');
              // } else {
              //   slideContainer.addClass('max-w-xl');
              //   slideContainer.removeClass('max-w-wide-panel');
              // }

              // this.showSlideout = true;
              //this.

              window.assetLibrary._expandSlider()

          },
          isSlideoutOpen() { return this.showSlideout === true },
          toggleSlideout() { this.showSlideout = !this.showSlideout },
          fetchPage(id) {
              //this.showSlideout = false;

              //12958
              //id = 12958;
              /*console.log(id,this.currentId);
              if(id != this.currentId){
                this.currentId = id;
                this.slideoutContent = 'Loading...';
                slideContentIframe = '<iframe class="w-full h-screen overflow-x-hidden overflow-y-scroll" src="'+`/asset-library/slideout/${id}`+'"></iframe>';
                this.slideoutContent = slideContentIframe;
                iFrameResize({log:true});
              }*/
              
              fetch(`/asset-library/slideout/${id}`)
                  .then(response => response.text())
                  .then((html) => {
                      //console.log(html);
                      this.assetSlideoutContent = html;
                      window.assetLibrary.attachEventsToAssetPanel();
                  });
          }
      }

    }


    _expandSlider(size){
      let slideContainer = $('.slideoutContentContainer');

      if(size){
        if(size == 'wide'){
          slideContainer.removeClass('max-w-xl');
          slideContainer.addClass('max-w-wide-panel');  
        } else {
          slideContainer.addClass('max-w-xl');
          slideContainer.removeClass('max-w-wide-panel');  
        }
      } else {
        if(slideContainer.hasClass('max-w-xl')){
          slideContainer.removeClass('max-w-xl');
          slideContainer.addClass('max-w-wide-panel');  
        } else {
          slideContainer.addClass('max-w-xl');
          slideContainer.removeClass('max-w-wide-panel');  
        }  
      }
    }

    _loadCollectionsList(){
      let assetId = $('a[data-assetId]').data('assetid');
      $('.collections-list > span').show();
      $('.collections-list > div').empty();
      fetch(`/asset-library/load-collections/${assetId}`)
      .then(response => response.text())
      .then((html) => {
          $('.collections-list > span').hide();
          $('.collections-list > div').empty().html(html);
      });
    }

    _enableButtons(button){
      $(button).removeClass('asset-button-disabled bg-color-1');
      $(button).addClass('asset-button-enabled bg-color-1');
    }

    _disableButtons(button){
      $(button).removeClass('asset-button-enabled bg-color-1');
      $(button).addClass('asset-button-disabled');
    }

    _isButtonEnabled(button){
      return $(button).hasClass('asset-button-enabled');
    }

}

window.assetLibrary = new assetLibrary();
window.assetLibrary.init();
